import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  ValueCards,
  ValueProps,
  Benefits,
  About,
  Cta,
  BodyCTA,
  Testimonials,
  Services,
  Plan,
  Accolades,
} from "../components/index"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <ValueCards />
    <ValueProps />
    <Benefits />
    <About />
    <BodyCTA />
    <Services />
    <Plan />
    <Accolades />
    <Testimonials />
    <Cta />
  </Layout>
)

export default IndexPage
